<template>
    <div class="examAdminScreen">
        <div v-for="item in views" :key="item._id.$id" class="item">
            <div :id="item.student_id" class="video"></div>
            <div>{{item.student_name}}</div>
            <div>{{item.account_name}}</div>
        </div>
    </div>
</template>

<script>
import * as QNRTC from 'pili-rtc-web'
import {getApplicationsByEvent,createRoomToken} from '../../api/index'
import { getUserId } from '../../utils/store'
const myRoom = new QNRTC.TrackModeSession()
export default {
    data(){
        return{
            roomId:this.$route.query.roomId,
            views:[]
        }
    },
    async mounted(){
       
        await this.fetchData()
        console.log(111111111111111,'我完成了')
        this.joinRoom()
    },
    methods:{
        async fetchData(){
            return new Promise(resolve=>{
                getApplicationsByEvent(this.$route.query.event).then(res=>{
                    if(res.data.code===0){
                        this.views = res.data.data
                    }
                    resolve(true)
                })
            })
        },
        async joinRoom () {
            console.log(2222222222222222,'我现在开始')
            let roomToken
            const result = await this.createRoomToken()
            if(result.code==0){
                roomToken = result.data.token
            }else{
                this.$message(result.msg)
                return;
            }
            try {
                // 加入房间
                await myRoom.joinRoomWithToken(roomToken)
                this.$message({
                    message:'房间加入成功',
                    type:'success'
                })
                // 如果加入房间后发现房间已经有人发布，就订阅他
                this.subscribeTracks(myRoom.trackInfoList)
            } catch (e) {
                console.error(e)
                this.$message(`加入房间失败！ErrorCode: ${e.code || ''}`)
                return
            }
            myRoom.on("track-add", async(tracks) => {
                this.subscribeTracks(tracks);
            });
         
        },
        createRoomToken () {
            return new Promise((resolve,reject)=>{
                createRoomToken(this.roomId,'screen'+getUserId(),'admin').then(res => {
                    resolve(res.data)
                })
            })
        },
        subscribeTracks (trackInfoList) {
            $info = {};
            for(let item of trackInfoList){
                $info[item.trackId] = 'low';
            }
            myRoom.subscribe(trackInfoList.map(t => t.trackId),false,info).then(tracks => {
                for (const track of tracks) {
                    let main = document.getElementById(track.userId);
                    track.play(main);
                }
            });
        }
        
    }
}
</script>

<style lang="less">
.examAdminScreen{
    display: flex;
    flex-wrap: wrap;
    .item{
        width: 200px;
        margin-left: 4px;
        text-align: center;
        .video{
            width: 100%;
            height: 120px;
            background: black;
        }

    }
}
</style>